import { defineStore } from 'pinia'
import { ref } from 'vue'
import { unsubscribeUser, subscribeUser } from '@/composables/useNotification'

const roles = {
    'manager': 10,
    'user': 100,
}

export const useUserStore = defineStore('user', () => {
    const userData = localStorage.getItem('user')
    const user = ref(null)
    const token = ref(localStorage.getItem('token'))
    const subscribeRegistration = ref(null)

    if (userData) {
        try {
            user.value = JSON.parse(userData);
        }
        catch (e) {}
    }

    function setUser (newUser) {
        user.value = newUser
        localStorage.setItem('user', JSON.stringify(newUser))
        setToken(newUser?.token)
        subscribeUser(subscribeRegistration.value)
    }

    function setToken (newToken) {
        token.value = newToken
        localStorage.setItem('token', newToken)
    }

    function setSubscribeRegistration (registration) {
        subscribeRegistration.value = registration
    }

    async function logout () {
        await unsubscribeUser(subscribeRegistration.value)
        user.value = null
        token.value = null
        localStorage.removeItem('token')
        localStorage.removeItem('user')
    }

    function isLoggedIn () {
        return !!token.value && token.value !== 'null' && token.value !== 'undefined'
    }

    function isRole (role = '') {
        return user.value?.role?.id === roles[role]
    }

    return { user, token, subscribeRegistration, setUser, setToken, setSubscribeRegistration, isLoggedIn, logout, isRole }
})
