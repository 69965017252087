export default {
    'app.name': 'UnicornBank',

    'common.currency.THB': 'Тайский бат',
    'common.currency.USD': 'Доллар США',
    'common.currency.RU': 'Российский рубль',
    'common.location': 'Местонахождение',
    'common.age': 'Возраст',
    'common.years': 'Лет',
    'common.email': 'E-mail',
    'common.login': 'Логин',
    'common.password': 'Пароль',
    'common.name': 'Имя',
    'common.date_birth': 'Дата рождения',
    'common.create_user': 'Создать пользователя',
    'common.date': 'Дата',
    'common.comment': 'Комментарий',
    'common.amount': 'Сумма',
    'common.copied': 'Скопировано',
    'common.attention': 'Внимание',
    'common.notification_need_grant': 'Вам нужно разрешить показ уведомлений',

    'date.today': 'Сегодня',
    'date.tomorrow': 'Завтра',

    'menu.home': 'Главная',
    'menu.profile': 'Профиль',
    'menu.jobs': 'Работа',
    'menu.about': 'О нас',
    'menu.auth': 'Авторизация',
    'menu.admin': 'Администрирование',

    'auth.login': 'Вход',
    'auth.registration': 'Регистрация',
    'auth.logout_confirm': 'Вы действительно хотите выйти?',

    'btn.login': 'Вход',
    'btn.register': 'Зарегистрироваться',
    'btn.save': 'Сохранить',
    'btn.ok': 'Ок',
    'btn.cancel': 'Отмена',
    'btn.yes': 'Да',
    'btn.no': 'Нет',
    'btn.grant': 'Разрешить',

    'bank.role.admin': 'Администратор',
    'bank.role.manager': 'Менеджер',
    'bank.role.user': 'Пользователь',
    'bank.payment-date': 'Дата выплат',
    'bank.balance_user': 'Баланс пользователя',
    'bank.up': 'Пополнить',
    'bank.down': 'Списать',
    'bank.no-payment-date': 'Нет даты выплат',
    'bank.recurrence.daily': 'Ежедневно',
    'bank.recurrence.weekly': 'Еженедельно',
    'bank.recurrence.monthly': 'Ежемесячно',
    'bank.recurrence.3-months': 'Каждые 3 месяца',
    'bank.recurrence.6-months': 'Каждые 6 месяцев',
    'bank.recurrence.yearly': 'Ежегодно',
    'bank.no_transactions': 'Нет транзакций',
    'bank.days_to_payment': 'Дней до оплаты',
    'bank.cancel_transaction_confirm': 'Вы действительно хотите отменить эту транзакцию?',
}
