import HomeView from '@/views/HomeView'
import AuthView from '@/views/AuthView'

// lazy-loaded
const AboutView = () => import('@/views/AboutView.vue')
const JobsView = () => import('@/views/JobsView.vue')
const ProfileView = () => import('@/views/ProfileView.vue')
const AdminView = () => import('@/views/admin/AdminView.vue')
const InviteView = () => import('@/views/InviteView.vue')

export default [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            layout: 'app',
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfileView,
        meta: {
            layout: 'app',
            auth: true,
        },
    },
    {
        path: '/jobs',
        name: 'jobs',
        component: JobsView,
        meta: {
            layout: 'app',
            auth: true,
        },
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView,
        meta: {
            layout: 'app',
        },
    },
    {
        path: '/auth',
        name: 'auth',
        component: AuthView,
        meta: {
            layout: 'guest',
        },
    },
    {
        path: '/admin',
        name: 'admin',
        component: AdminView,
        meta: {
            layout: 'admin',
            auth: true,
        },
    },
    {
        path: '/invite/:catchAll(.*)',
        name: 'invite',
        component: InviteView,
        meta: {
            layout: 'guest',
        },
    },
]
