import { register } from 'register-service-worker'

const baseUrl = process.env.VUE_APP_BASE_URL
const isProduction = process.env.VUE_APP_NODE_ENV === 'production'

register(`${baseUrl}/service-worker.js`, {
    ready() {
        console.log(
            'App is being served from cache by a service worker.\n' +
            'For more details, visit https://goo.gl/AFskqB'
        );
    },
    registered(registration) {
        console.log('Service worker has been registered.')
    },
    cached() {
        console.log('Content has been cached for offline use.')
    },
    updatefound() {
        console.log('New content is downloading.')
    },
    updated() {
        console.log('New content is available; please refresh.')
    },
    offline() {
        console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
        console.error('Error during service worker registration:', error)
    },
})

self.addEventListener('push', event => {
    const data = event.data.json()
    self.registration.showNotification(data.title, {
        body: data.body,
        icon: '../public/img/icons/android-chrome-192x192.png',
    })
})
