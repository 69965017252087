import { useUserStore } from '@/stores/user'

export async function authMiddleware (to, from, next) {
    const userStore = useUserStore()

    if (to?.meta?.auth) {
        if (!userStore.isLoggedIn()) {
            next({ name: 'auth' })
        }
        else {
            next()
        }
    }
    else {
        next()
    }
}
