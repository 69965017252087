import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import { setupPrimeVue } from '@/config/primevue'
import { setupMaterialIcons } from '@/config/materialIcons'
import i18nConfig from '@/config/i18n'
import { useUserStore } from '@/stores/user'
import { subscribeUser } from '@/composables/useNotification'

const pinia = createPinia()
const i18n = createI18n(i18nConfig)
const app = createApp(App)

app.use(pinia)
app.use(router)
app.use(i18n)

setupPrimeVue(app)
setupMaterialIcons(app)

app.mount('#app')
