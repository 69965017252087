export async function loadLayoutMiddleware(to, from, next) {
    try {
        const layout = to?.meta?.layout || 'default'
        const layoutComponent = await import(`@/layouts/${layout}.vue`)
        to.meta.layoutComponent = layoutComponent.default
    }
    catch (e) {
        console.error('Error occurred in processing of layouts: ', e)
    }
    finally {
        next()
    }
}
