import { ref } from 'vue'
import { api } from '@/composables/useApi'
import { useUserStore } from '@/stores/user'

const granted = 'granted'
const isPermissionGranted = ref(false)

export default function useNotification () {
    const requestPermission = async () => {
        const result = await Notification.requestPermission()
        isPermissionGranted.value = result === granted
    }

    const showNotification = (title, body, icon) => {
        if (isPermissionGranted.value) {
            const options = { body, icon }
            new Notification(title, options)
        }
        else {
            console.error('Notification permission not granted')
        }
    }

    isPermissionGranted.value = Notification.permission === granted

    return {
        isPermissionGranted,
        requestPermission,
        showNotification
    }
}

export async function checkSubscription () {
    const userStore = useUserStore()

    if (userStore.subscribeRegistration) {
        if (userStore.isLoggedIn()) {
            await subscribeUser(userStore.subscribeRegistration)
        }
        else {
            await unsubscribeUser(userStore.subscribeRegistration)
        }
    }
}

export async function subscribeUser(registration) {
    console.log('Subscribe user')

    if (!registration.pushManager) {
        console.log('Push notifications are not supported')
        return
    }

    const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY

    try {
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
        })
        console.log('The user is subscribed to push notifications:', subscription)

        try {
            const response = await api('post:/subscribe', {
                body: JSON.stringify(subscription),
            })
            console.log('Server response:', response.data)
        }
        catch (error) {
            console.error('Error during sending subscription to server:', error)
        }
    }
    catch (error) {
        console.log('Failed to subscribe user:', error)
    }
}

export async function unsubscribeUser(registration) {
    console.log('Unsubscribe user')

    if (!registration.pushManager) {
        console.log('Push notifications are not supported')
        return
    }

    try {
        const subscription = await registration.pushManager.getSubscription()
        if (subscription) {
            const unsubscribed = await subscription.unsubscribe()

            if (unsubscribed) {
                console.log('The user is unsubscribed from push notifications')

                try {
                    const response = await api('delete:/subscribe', {
                        body: JSON.stringify(subscription),
                    })
                    console.log('Server response:', response.data)
                }
                catch (error) {
                    console.error('Error during sending subscription to server:', error)
                }
            }
        }
        else {
            console.log('The user is not subscribed to push notifications')
        }
    }
    catch (error) {
        console.error('Error during unsubscribe the user:', error)
    }
}

export function urlBase64ToUint8Array (base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4)
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }

    return outputArray
}
