import axios from 'axios'
import i18n from '@/config/i18n'

const apiUrl = process.env.VUE_APP_API_URL

axios.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token')
    const { locale } = i18n

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    config.headers['Accept-Language'] = locale

    return config
}, (error) => {
    return Promise.reject(error)
})

export function api (url = '', data = {}) {
    return new Promise((resolve, reject) => {
        const method = url.includes(':') ? url.split(':')[0] : 'get'
        const urlRequest = apiUrl + url.replace(`${method}:`, '')

        axios[method](urlRequest, data)
            .then(response => {
                resolve({ data: response.data, error: null })
            })
            .catch(error => {
                resolve({ data: null, error })
            })
    })
}

export function formatErrors (errors = null) {
    if (!errors || !errors.length) return {}

    const formattedErrors = {}
    errors.forEach(error => {
        formattedErrors[error.path[0]] = error.message
    })
    return formattedErrors
}
