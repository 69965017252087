import Wallet from 'vue-material-design-icons/Wallet.vue'
import Wifi from 'vue-material-design-icons/Wifi.vue'
import Home from 'vue-material-design-icons/Home.vue'
import Account from 'vue-material-design-icons/Account.vue'
import ShieldAccount from 'vue-material-design-icons/ShieldAccount.vue'
import Information from 'vue-material-design-icons/Information.vue'
import Login from 'vue-material-design-icons/Login.vue'
import Logout from 'vue-material-design-icons/Logout.vue'
import BadgeAccount from 'vue-material-design-icons/BadgeAccount.vue'
import Email from 'vue-material-design-icons/Email.vue'
import Lock from 'vue-material-design-icons/Lock.vue'
import BorderColor from 'vue-material-design-icons/BorderColor.vue'
import Numeric from 'vue-material-design-icons/Numeric.vue'
import HandCoin from 'vue-material-design-icons/HandCoin.vue'
import AccountPlus from 'vue-material-design-icons/AccountPlus.vue'
import ArrowUp from 'vue-material-design-icons/ArrowUp.vue'
import ArrowDown from 'vue-material-design-icons/ArrowDown.vue'
import Calendar from 'vue-material-design-icons/Calendar.vue'
import Pencil from 'vue-material-design-icons/Pencil.vue'
import ContentCopy from 'vue-material-design-icons/ContentCopy.vue'
import Cancel from 'vue-material-design-icons/Cancel.vue'
import Briefcase from 'vue-material-design-icons/Briefcase.vue'
import AlertBox from 'vue-material-design-icons/AlertBox.vue'

export function setupMaterialIcons (app) {
    app.component('IconWallet', Wallet)
    app.component('IconWifi', Wifi)
    app.component('IconHome', Home)
    app.component('IconAccount', Account)
    app.component('IconShieldAccount', ShieldAccount)
    app.component('IconInformation', Information)
    app.component('IconLogin', Login)
    app.component('IconLogout', Logout)
    app.component('IconBadgeAccount', BadgeAccount)
    app.component('IconEmail', Email)
    app.component('IconLock', Lock)
    app.component('IconBorderColor', BorderColor)
    app.component('IconNumeric', Numeric)
    app.component('IconHandCoin', HandCoin)
    app.component('IconAccountPlus', AccountPlus)
    app.component('IconArrowUp', ArrowUp)
    app.component('IconArrowDown', ArrowDown)
    app.component('IconCalendar', Calendar)
    app.component('IconPencil', Pencil)
    app.component('IconContentCopy', ContentCopy)
    app.component('IconCancel', Cancel)
    app.component('IconAlertBox', AlertBox)
}
