import { createRouter, createWebHistory } from 'vue-router'
import { loadLayoutMiddleware } from './middleware/loadLayoutMiddleware'
import { authMiddleware } from './middleware/authMiddleware'
import routes from './routes'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(authMiddleware)
router.beforeEach(loadLayoutMiddleware)

export default router
