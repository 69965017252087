import ru from './messages/ru'
import en from './messages/en'

export default {
    locale: 'ru',
    fallbackLocale: 'en',
    legacy: false,
    messages: {
        ru,
        en,
    }
}
