import PrimeVue from 'primevue/config'
import Menubar from 'primevue/menubar'
import Avatar from 'primevue/avatar'
import Card from 'primevue/card'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ScrollPanel from 'primevue/scrollpanel'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Password from 'primevue/password'
import Dropdown from 'primevue/dropdown'
import Listbox from 'primevue/listbox'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Image from 'primevue/image'
import RadioButton from 'primevue/radiobutton'
import Dialog from 'primevue/dialog'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Calendar from 'primevue/calendar'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmationService from 'primevue/confirmationservice'

export function setupPrimeVue (app) {
    app.use(PrimeVue)
    app.use(ToastService)
    app.use(ConfirmationService)
    app.component('Menubar', Menubar)
    app.component('Avatar', Avatar)
    app.component('Card', Card)
    app.component('Button', Button)
    app.component('DataTable', DataTable)
    app.component('Column', Column)
    app.component('ScrollPanel', ScrollPanel)
    app.component('InputText', InputText)
    app.component('InputNumber', InputNumber)
    app.component('Password', Password)
    app.component('Dropdown', Dropdown)
    app.component('Listbox', Listbox)
    app.component('Toast', Toast)
    app.component('TabView', TabView)
    app.component('TabPanel', TabPanel)
    app.component('Image', Image)
    app.component('RadioButton', RadioButton)
    app.component('Dialog', Dialog)
    app.component('Accordion', Accordion)
    app.component('AccordionTab', AccordionTab)
    app.component('Calendar', Calendar)
    app.component('ConfirmDialog', ConfirmDialog)
}
