export default {
    'app.name': 'UnicornBank',

    'common.currency.THB': 'Thai baht',
    'common.currency.USD': 'Dollar USA',
    'common.currency.RU': 'Russian ruble',
    'common.location': 'Location',
    'common.age': 'Age',
    'common.years': 'Years',
    'common.email': 'E-mail',
    'common.login': 'Login',
    'common.password': 'Password',
    'common.name': 'Name',
    'common.date_birth': 'Date of Birth',
    'common.create_user': 'Create new user',
    'common.date': 'Date',
    'common.comment': 'Comment',
    'common.amount': 'Amount',
    'common.copied': 'Copied',
    'common.Attention': 'Attention',
    'common.notification_need_grant': 'You need to grant permission to show notifications',

    'date.today': 'Today',
    'date.tomorrow': 'Tomorrow',

    'menu.home': 'Home',
    'menu.profile': 'Profile',
    'menu.jobs': 'Jobs',
    'menu.about': 'About',
    'menu.auth': 'Authorization',
    'menu.admin': 'Administration',

    'auth.login': 'Login',
    'auth.registration': 'Registration',
    'auth.logout_confirm': 'Are you sure you want to log out?',

    'btn.login': 'Login',
    'btn.register': 'Register',
    'btn.save': 'Save',
    'btn.ok': 'Ok',
    'btn.cancel': 'Cancel',
    'btn.yes': 'Yes',
    'btn.no': 'No',
    'btn.grant': 'Grant',

    'bank.role.admin': 'Administrator',
    'bank.role.manager': 'Manager',
    'bank.role.user': 'User',
    'bank.payment-date': 'Payment date',
    'bank.balance_user': 'Balance user',
    'bank.up': 'Up',
    'bank.down': 'Down',
    'bank.no-payment-date': 'No payment date',
    'bank.recurrence.daily': 'Daily',
    'bank.recurrence.weekly': 'Weekly',
    'bank.recurrence.monthly': 'Monthly',
    'bank.recurrence.3-months': 'Every 3 months',
    'bank.recurrence.6-months': 'Every 6 months',
    'bank.recurrence.yearly': 'Yearly',
    'bank.no_transactions': 'No transactions',
    'bank.days_to_payment': 'Days to payment',
    'bank.cancel_transaction_confirm': 'Are you sure you want to cancel this transaction?',
}
