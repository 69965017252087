import { useToast as useOriginToast } from 'primevue/usetoast'

export function useToast () {
    const toast = useOriginToast()

    function addDefault(message, type = 'success') {
        toast.add({
            detail: message,
            severity: type,
            life: 5000,
            group: 'br',
        })
    }

    return { add: addDefault }
}
